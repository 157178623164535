import { Api as apiCall, createAsyncAction } from '@lyfta/components-data'

export const LOAD_TAG_GROUPS = createAsyncAction('tags/LOAD_TAG_GROUPS')
export const CREATE_TAG_GROUP = createAsyncAction('tags/CREATE_TAG_GROUP')
export const UPDATE_TAG_GROUP = createAsyncAction('tags/UPDATE_TAG_GROUP')
export const LOAD_TAG_GROUP = createAsyncAction('tags/LOAD_TAG_GROUP')
export const DELETE_TAG_GROUP = createAsyncAction('tags/DELETE_TAG_GROUP')
export const REQUEST_UPDATE_TAG_GROUP = 'tags/REQUEST_UPDATE_TAG_GROUP'
export const REQUEST_CREATE_TAG_GROUP = 'tags/REQUEST_CREATE_TAG_GROUP'

export const clearCurrent = () => ({ type: LOAD_TAG_GROUP.CLEAR })
export const loadTagGroups = ({
  filter = {},
  page = {},
  sort = 'name',
} = {}) => {
  return apiCall({
    endpoint: '/tag_groups',
    query: {
      include: 'tags,curriculums',
      filter,
      page,
      sort,
    },
    types: LOAD_TAG_GROUPS,
    paged: true,
  })
}
export const loadTagGroup = id =>
  apiCall({
    endpoint: `/tag_groups/${id}`,
    query: {
      include: 'tags,curriculums',
    },
    types: LOAD_TAG_GROUP,
    paged: true,
  })
export const deleteTagGroup = id =>
  apiCall({
    method: 'DELETE',
    endpoint: `/tag_groups/${id}`,
    query: {},
    types: DELETE_TAG_GROUP,
  })
export const createTagGroup = (attributes, relationships) =>
  apiCall({
    method: 'POST',
    endpoint: '/tag_groups',
    query: {
      data: {
        type: 'tagGroups',
        attributes,
        relationships,
      },
    },
    types: CREATE_TAG_GROUP,
  })
export const updateTagGroup = (
  attributes,
  relationships,
  resetCurriculum = true,
) => {
  let newRelationships = {}
  // when a user remove all the curriculum in a TG this action doesnt take place as there is not relationship and the BE doenst remove all of them
  // https://app.asana.com/0/1201176239385966/1209056131409095/f

  if (!relationships && resetCurriculum) {
    newRelationships.curriculum = { data: [] }
  }
  // eslint-disable-next-line no-const-assign
  else newRelationships = relationships

  return apiCall({
    method: 'PATCH',
    endpoint: `/tag_groups/${attributes.id}?include=tags,curriculums`,
    types: UPDATE_TAG_GROUP,
    query: {
      data: {
        type: 'tagGroups',
        attributes,
        relationships: newRelationships,
      },
    },
  })
}
export const requestUpdateTagGroup = (attributes, resolve) => ({
  type: REQUEST_UPDATE_TAG_GROUP,
  attributes,
  resolve,
})
export const requestCreateTagGroup = (attributes, resolve) => ({
  type: REQUEST_CREATE_TAG_GROUP,
  attributes,
  resolve,
})
