import { css, styled, themeGet } from '@lyfta/components-theme'
import ReactModal from 'react-modal'
import withStyledClassNames from 'with-styled-class-names'

import { Close as CloseIcon } from '../../Assets/Svg'
import { Box } from '../Box'
import { Flex } from '../Flex'
import TextNode from '../TextNode'

export const Close = styled(CloseIcon).attrs(() => ({
  fill: 'gray',
}))`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 8px;
  width: 36px;
  height: 36px;
  cursor: pointer;
`
export const Container = withStyledClassNames(
  {
    className: {
      base: css`
        display: flex;
        position: relative;
        outline: none;
        opacity: ${p =>
          p.startAnimation?.opacity ? p.startAnimation.opacity : '0'};
        top: ${p => (p.startAnimation?.top ? p.startAnimation.top : '0%')};
        transform: ${p =>
          p.startAnimation?.transform
            ? p.startAnimation.transform
            : 'scale(0.4)'};
        overflow: hidden;
        border-radius: 10px;
        min-height: ${p => (p.minHeight ? p.minHeight : '200px')};
        min-width: ${p => (p.minWidth ? p.minWidth : '320px')};
        max-width: ${p => (p.maxWidth ? p.maxWidth : 'auto')};
        max-height: 90%;
        background: ${p =>
          p.background
            ? themeGet(`colors.${p.background}`)
            : themeGet('colors.white')};
        /* box-shadow: 10px 10px 20px -10px ${themeGet(
          'colors.blackOpacity',
        )}; */
        box-shadow: ${p =>
          p.hasIcon
            ? 'none'
            : `10px 10px 20px -10px ${themeGet('colors.blackOpacity')}`};
        background: ${p =>
          p.hasIcon ? `none` : `${themeGet('colors.white')}`};

        transition: opacity ${p =>
          p.closeAnimation?.transitionDuration
            ? `${p.closeAnimation.transitionDuration}ms`
            : themeGet('transitionTime')} ${p =>
        p.closeAnimation?.transitionTimingFunction
          ? p.closeAnimation.transitionTimingFunction
          : ''}, top ${p =>
        p.closeAnimation?.transitionDuration
          ? `${p.closeAnimation.transitionDuration}ms`
          : themeGet('transitionTime')}, transform ${p =>
        p.closeAnimation?.transitionDuration
          ? `${p.closeAnimation.transitionDuration}ms`
          : themeGet('transitionTime')};
        transition-delay: 100ms;

        > * {
          flex: auto;
        }
      `,
      afterOpen: css`
        top: 0%;
        opacity: 1;
        transform: scale(1);
        min-width: ${p => (p.minWidth ? p.minWidth : '0px')};
      `,
      beforeClose: css`
        opacity: ${p =>
          p.closeAnimation?.opacity ? p.closeAnimation.opacity : '0'};
        top: ${p => (p.closeAnimation?.top ? p.closeAnimation.top : '0%')};
      `,
    },
    overlayClassName: {
      base: css`
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${themeGet('colors.blackOpacity')};
        z-index: 1050;
        width: 100%;
        height: 100%;
        outline: none;
        opacity: 0;
        transition: opacity ${themeGet('transitionTime')};
      `,
      afterOpen: css`
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.9);
      `,
      beforeClose: css`
        opacity: 0;
      `,
    },
  },
  ReactModal,
)

// HAS icon only. no transition
// export const Container = withStyledClassNames(
//   {
//     className: {
//       base: css`
//         display: flex;
//         position: relative;
//         outline: none;
//         opacity: 0;
//         transform: scale(0.4);
//         overflow: hidden;
//         border-radius: 10px;
//         min-height: ${p => (p.minHeight ? p.minHeight : '200px')};
//         min-width: ${p => (p.minWidth ? p.minWidth : '320px')};
//         max-width: ${p => (p.maxWidth ? p.maxWidth : 'auto')};
//         max-height: 90%;
//         box-shadow: ${p =>
//           p.hasIcon
//             ? 'none'
//             : `10px 10px 20px -10px ${themeGet('colors.blackOpacity')}`};
//         background: ${p =>
//           p.hasIcon ? `none` : `${themeGet('colors.white')}`};

//         transition: all ${themeGet('transitionTime')};
//         transition-delay: 100ms;

//         > * {
//           flex: auto;
//         }
//       `,
//       afterOpen: css`
//         opacity: 1;
//         transform: scale(1);
//         min-width: ${p => (p.minWidth ? p.minWidth : '0px')};
//       `,
//       beforeClose: css`
//         opacity: 0;
//       `,
//     },
//     overlayClassName: {
//       base: css`
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         position: fixed;
//         top: 0;
//         left: 0;
//         right: 0;
//         bottom: 0;
//         background-color: ${themeGet('colors.blackOpacity')};
//         z-index: 9998;
//         width: 100%;
//         height: 100%;
//         outline: none;
//         opacity: 0;

//         transition: all ${themeGet('transitionTime')};
//       `,
//       afterOpen: css`
//         opacity: 1;
//         background-color: rgb(0, 0, 0, 0.3);
//       `,
//       beforeClose: css`
//         opacity: 0;
//       `,
//     },
//   },
//   ReactModal,
// )

export const IconModal = styled(Flex)`
  align-items: flex-end;
  margin-bottom: -50px;
`

// export const Wrapper = styled(Flex)`
//   flex-direction: column;
//   max-width: ${p => (p.maxWidth ? p.maxWidth : '100%')};
//   align-items: center;
//   outline: none;
//   margin: ${p => (p.margin ? p.margin : '30px 38px')};
//   background-color: ${themeGet('colors.WHITE')};
//   ${p =>
//     p.specialOverflowSettings &&
//     css`
//       overflow: hidden;
//     `};
// `

export const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  max-width: ${p => (p.maxWidth ? p.maxWidth : '100%')};
  align-items: center;
  outline: none;
  margin: ${p => (p.margin ? p.margin : '30px 38px')};
  background: ${p => (p.background ? p.background : themeGet('colors.white'))};

  box-shadow: ${p =>
    p.hasIcon
      ? `10px 10px 20px -10px ${themeGet('colors.blackOpacity')}`
      : 'none'};
  border-radius: 10px;
  padding: ${p => (p.padding ? p.padding : '0px 20px')};
  ${p =>
    p.specialOverflowSettings &&
    css`
      overflow: hidden;
    `};

  ${p =>
    p.hasIcon &&
    css`
      &:before {
        content: '';
        position: absolute;
        bottom: 10px; // Ajusta esto según la posición deseada
        left: 0; // Ajusta esto para alinear con el icono
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent ${themeGet('colors.white')}
          transparent;
        transform: translateX(-50%); // Centra la flecha debajo del Wrapper

        border-style: solid;
        border-width: 0 40px 40px 0;
        border-color: transparent #ffffff transparent transparent;
        /* top: 50%; */
        /* left: -20px; */
        margin-top: -10px;
      }
    `};

  /* position: relative;
    &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-width: 0 20px 20px 0;
    border-color: transparent #000000 transparent transparent;
    top: 50%;
    left: -20px;
    margin-top: -10px; */
`

export const ContentWrapper = styled(Flex)`
  /* flex-direction: row;
  flex: 1;
  overflow: hidden;
  width: ${p => (p.hideClose ? '95%' : '100%')}; */
  flex-direction: column;
  flex: 1;
  font-size: 16px; // Body 1
  font-weight: 400; // Body 1
  letter-spacing: 0.5px; // Body 1
  line-height: 24px; // Body 1
  width: 100%;
  ${p =>
    p.specialOverflowSettings &&
    css`
      overflow: auto;
    `};
`
export const DefaultTextStyle = styled(TextNode)`
  font-weight: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: left;
`
// export const Title = styled(Flex)`
//   ${props => !props.fitContent && 'padding: 16px 32px'};
//   ${props => !props.fitContent && 'margin-top: 16px'};
//   font-size: 20px;
//   justify-content: center;
//   width: 100%;
//   font-weight: 600;
// `
export const Title = styled(Flex).attrs(props => ({
  mb: 2,
  ...props,
}))`
  font-weight: 700; // Subtitle 2
  font-size: 23px; // Subtitle 2
  line-height: 32px; // Subtitle 2
  letter-spacing: 0.5px; // Subtitle 2
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  color: ${themeGet('colors.black_700')};
`
export const Subtitle = styled(Flex).attrs(({ fixHeader }) => ({
  mb: fixHeader ? 3 : 2,
}))`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px; // Subtitle 2
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  color: ${themeGet('colors.black_600')};
`

export const Text = styled(Flex)`
  flex-direction: column;
  ${props => (props.fitContent ? '' : 'padding: 16px 32px')};
  justify-content: center;
  width: 100%;
  ${props => (props.fitContent ? '' : 'margin-top: 10px;')};

  > b {
    font-weight: 500;
  }
`

export const Buttons = styled(Flex)`
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  width: 100%;
  padding: 32px 32px;

  > button {
    min-width: 160px;
    margin-left: 8px;
    padding: 20px 32px;
  }
`

export const Header = styled(Box).attrs(props => ({
  ...props,
}))`
  overflow: hidden;
  /* position: fixed; */
  /* top: 0; */
  /* padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px; */
  width: 100%;
  box-shadow: ${p => (p.shadow ? p.shadow : 'none')};
`

export const Description = styled(Flex).attrs(() => ({
  mt: 2,
}))`
  flex-direction: column;
  align-self: stretch;
  flex-grow: 0;
  color: ${themeGet('colors.black_700')};
  ${props =>
    props.textWithParagraphs &&
    css`
      display: flex;
      flex-direction: column;
      gap: 24px;
    `};
`

export const Footer = styled(Flex)``

export const ButtonsContainer = styled(Flex).attrs(() => ({
  flexDirection: ['column-reverse', 'row'],
  pt: 5,
}))`
  display: flex;
  gap: 16px;
  width: 100%;
  > * {
    flex: 1;
  }
`
