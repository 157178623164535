import { appEnv } from '@lyfta/components-config'
import { generateSystemPath } from '@lyfta/components-ui'

export const TYPE_SERIES = 'series'
export const TYPE_STORY_WORLD = 'story_world'
export const TYPE_VIDEO = 'video'
export const TYPE_ARTICLE = 'article'
export const TYPE_360_VIDEO = '360_video'
export const TYPE_LESSON_PLAN_TEMPLATE = 'lesson_plan_template'
export const TYPE_COLLECTION = 'collection'
export const TYPE_SOUND_CLIP = 'sound_clip'
export const TYPE_360_SCENE = '360_scene'

export const TYPE_GET_STARTED = 'getStarted'
export const TYPE_EXPLORE = 'explore'
export const TYPE_NEW_SEARCH = 'newSearch'
export const TYPE_UNLOCK_CONTENT = 'unlockContent'
export const TYPE_STUDIES = 'studies'
export const TYPE_ADDIONAL_RESOURCES = 'additionalResources'

export const contentTypes = {
  [TYPE_SERIES]: 'Storyworld Series',
  [TYPE_STORY_WORLD]: 'Storyworld',
  [TYPE_VIDEO]: 'Film',
  [TYPE_360_VIDEO]: '360 video',
  [TYPE_ARTICLE]: 'Article',
  [TYPE_LESSON_PLAN_TEMPLATE]: 'Lesson plan',
  [TYPE_COLLECTION]: 'Collection',
}

export const excludeContentTypesLyftaContent = [
  TYPE_LESSON_PLAN_TEMPLATE,
  TYPE_SOUND_CLIP,
  TYPE_360_VIDEO,
]

// NOTE: creating the obj for reproducible search. Keys are in plural to make things easier to the marketing team.
export const CONTENT_TYPE_PATH_REVERSE = {
  series: TYPE_SERIES,
  storyworlds: TYPE_STORY_WORLD,
  videos: TYPE_VIDEO,
  '360_videos': TYPE_360_VIDEO,
  articles: TYPE_ARTICLE,
  lessonPlans: TYPE_LESSON_PLAN_TEMPLATE,
  collections: TYPE_COLLECTION,
}

export const CONTENT_TYPE_PATH = {
  [TYPE_SERIES]: 'series',
  [TYPE_STORY_WORLD]: 'storyworlds',
  [TYPE_VIDEO]: 'films',
  [TYPE_360_VIDEO]: '360',
  [TYPE_ARTICLE]: 'articles',
  [TYPE_LESSON_PLAN_TEMPLATE]: 'lyfta-lessons',
  [TYPE_COLLECTION]: 'collections',
}
// TODO: THIS SHOULDNT BE HERE
export default {
  content: {
    storyworld: {
      root: generateSystemPath('content'),
      item: generateSystemPath(['storyworld'], true, false, {
        prefix: 'content/',
      }),
      edit: generateSystemPath(['storyworld'], true, true, {
        prefix: 'content/',
      }),
      content: {
        edit: generateSystemPath(['storyworld', 'content'], true, true, {
          prefix: 'content/',
        }),
      },
    },
    edit: (parentId, id) => {
      return `/storyworlds/${parentId}/content/${id}/edit`
    },
  },
}

export const SEARCH_TABS = [
  {
    type: 'all_content',
    path: 'all',
    id: 'allContent',
  },
  {
    type: 'story_world',
    path: 'storyworlds',
    id: 'storyworlds',
  },
  {
    type: 'video',
    path: 'films',
    id: 'films',
  },
  {
    type: 'article',
    path: 'articles',
    id: 'articles',
  },
  {
    type: '360_video',
    path: '360',
    id: '360',
  },
  {
    type: 'lesson_plan_template',
    path: 'lyfta-lessons',
    id: 'lessonPlans',
  },
]

// This is a whitelist of 2 character's letter that need to be converted
// before being send to the api
export const whitelist = {
  ai: 'artificial intelligence',
  la: 'Los Angeles',
}

export const initialStateFilter = {
  types: {
    input: 'button',
    name: 'types',
    default: 'all_content',
    options: {
      all_content: true,
      story_world: false,
      video: false,
      article: false,
      lesson_plan_template: false,
      '360_video': false,
      series: false,
      collection: false,
    },
  },
  ageRanges: {
    input: 'button',
    name: 'ageRanges',
    default: 'allAges',
    options: {
      allAges: true,
      ages5_7: false,
      ages7_11: false,
      ages11_14: false,
      ages14_16: false,
      ages16plus: false,
    },
  },
  lessonPlanTypes: {
    input: 'button',
    name: 'lessonPlanTypes',
    default: 'allLessonPlans',
    options: {
      allLessonPlans: true,
      lessons: false,
      assemblies: false,
      lyftaTimes: false,
      accessible: false,
    },
  },
  bbfcRatings: {
    input: 'button',
    name: 'bbfcRatings',
    options: {
      one: false,
      two: false,
      three: false,
    },
  },
  filmDurations: {
    input: 'button',
    name: 'filmDurations',
    default: 'allLengths',
    options: {
      allLengths: true,
      under5: false,
      mins510: false,
      over10: false,
    },
  },
  subjects: {
    input: 'checkbox',
    name: 'subjects',
    source: 'api',
    options: [],
  },
  tags: {
    // Tags show up from the API. We only store those that they are selected.
    input: 'checkbox',
    name: 'tags',
    source: 'api',
    options: [],
  },
}

export const AGE_ID_MAP = {
  ages5_7: 1,
  ages7_11: 2,
  ages11_14: 3,
  ages14_16: 4,
  ages16plus: 5,
}

export const LESSON_PLAN_TYPE_MAP = {
  lessons: 1,
  assemblies: 2,
  lyftaTimes: 3,
  accessible: 4,
}

export const DURATION_ID_MAP = {
  under5: 1,
  mins510: 2,
  over10: 3,
}

export const BBFC_RATING_MAP = {
  one: 1,
  two: 2,
  three: 3,
}

export const BBFC_RATING_MAP_NAMES = [
  'Universal',
  'Parental guidance',
  '12/12A',
]
export const LESSON_PLAN_TYPE_MAP_NAMES = [
  'lessons',
  'assemblies',
  'lyfta times',
  'accessible',
]

export const AGE_RANGE_MAP_NAMES = ['5-7', '7-11', '11-14', '14-16', '16+']

export const DURATION_MAP_NAMES = ['<5', '5-10', '>10']

export const CONTENT_TYPE_MAP = {
  story_world: 'story_world',
  video: 'video',
  article: 'article',
  lesson_plan_template: 'lesson_plan_template',
  '360_video': '360_video',
  series: 'series',
  collection: 'collection',
}

export const GOOGLE_KEY_MAP = {
  subjects: 'subject',
  ageRanges: 'age_range',
  lessonPlanTypes: 'lesson_plan_type',
  filmDurations: 'film_duration',
  bbfcRatings: 'bbfc_rating',
  types: 'content_type',
  tags: 'content_tag_IDs',
}

export const GET_STARTED_LYFTA_TYPE = {
  [TYPE_GET_STARTED]: 'getStarted',
  [TYPE_EXPLORE]: 'explore',
  [TYPE_NEW_SEARCH]: 'newSearch',
  [TYPE_UNLOCK_CONTENT]: 'unlockContent',
  [TYPE_STUDIES]: 'studies',
  [TYPE_ADDIONAL_RESOURCES]: 'additionalResources',
}

export const GET_STARTED_LYFTA = [
  {
    id: 'getStarted',
    title: 'getStarted.title',
    subtitle: 'getStarted.subtitle',
    icon: 'Flag',
    intercom: appEnv === 'production' ? 412658 : 262113,
  },
  {
    id: 'explore',
    title: 'explore.title',
    subtitle: 'explore.subtitle',
    icon: 'Rocket',
    intercom: appEnv === 'production' ? 320836 : 267327,
  },
  {
    id: 'newSearch',
    title: 'newSearch.title',
    subtitle: 'newSearch.subtitle',
    icon: 'NewSearch',
    intercom: appEnv === 'production' ? 412661 : 267483,
  },
]

export const GET_EXTRA_LYFTA = [
  {
    id: 'unlockContent',
    title: 'unlockContent.title',
    subtitle: 'unlockContent.subtitle',
    icon: 'Unlock',
    action: 'calendly',
  },
  {
    id: 'studies',
    title: 'studies.title',
    subtitle: 'studies.subtitle',
    icon: 'Studies',
    link: 'https://www.lyfta.com/impact',
  },
  {
    id: 'additionalResources',
    title: 'additionalResources.title',
    subtitle: 'additionalResources.subtitle',
    icon: 'Certificate',
    link: 'https://www.lyfta.com/blog',
  },
]
