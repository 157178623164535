import {
  styled,
  themeGet,
  walsheimAndFallbackFontFamily,
} from '@lyfta/components-theme'
import shadows from '@lyfta/components-theme/src/themes/Main/shadows'
import { Arrow, Box, Flex } from '@lyfta/components-ui'
import {
  AccordionItem as ReactAccordionItem,
  AccordionItemButton as ReactAccordionItemButton,
} from 'react-accessible-accordion'

export const Container = styled(Flex)`
  flex: 1;
  flex-direction: column;
`
export const ContainerUL = styled(Flex).attrs(() => ({
  mx: 3,
  mt: 3,
}))`
  flex-direction: column;
  justify-content: start;

  > li {
    margin-left: 7px;
    margin-bottom: 15px;
    line-height: 28px;
  }
`
export const TransLinkContainer = styled(Flex)`
  flex-direction: column;

  width: 100%;
  color: ${themeGet('colors.black_600')};
  font-size: 19px;
  line-height: 28px;
  > a {
    padding: 0;
  }
`
export const Content = styled(Flex)`
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  margin-right: ${props => (props.tags ? 316 : 0)}px;
`

export const SearchResultsFlex = styled(Flex).attrs(() => ({
  mx: 3,
  mt: 3,
}))`
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
  flex-direction: column;
  /* grid-gap: 16px; */
  justify-content: start;
  overflow-y: auto;
`

export const SearchResultsGrid = styled(Flex).attrs(() => ({
  mx: 3,
  mt: 3,
  mb: 2,
}))`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 16px;
  justify-content: start;
  /* overflow-y: auto; */
  width: 100%;
`

export const PaginationWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
  align-items: center;
  height: 64px;
  border-bottom: 1px solid ${themeGet('colors.neutral_400')};
`

export const Next = styled(Box).attrs(props => ({
  as: Arrow,
  direction: 'right',
  size: 10,
  mr: 4,
  color: props.disabled ? 'dustyGray' : 'primary',
}))``

export const Previous = styled(Box).attrs(props => ({
  as: Arrow,
  direction: 'left',
  size: 10,
  mr: 4,
  color: props.disabled ? 'dustyGray' : 'primary',
}))``

export const Current = styled(Box).attrs(() => ({
  mr: 4,
}))`
  color: ${themeGet('colors.dustyGray')};
`

export const PageSize = styled(Flex).attrs(() => ({
  mr: 4,
}))`
  color: ${themeGet('colors.dustyGray')};
`

export const Size = styled(Flex).attrs(() => ({
  ml: 2,
}))`
  justify-content: space-between;
  align-items: center;
  color: ${themeGet('colors.dustyGray')};

  > div {
    margin-left: 7px;
    margin-bottom: 2px;
    border-color: ${themeGet('colors.dustyGray')};
  }
`
export const HelpLink = styled.a`
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  color: ${themeGet('colors.primary')};
`
export const TopBarLeft = styled(Flex)`
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines you want to display */
  -webkit-box-orient: vertical;
`

export const DescriptionRibbonRow = styled.p`
  color: ${themeGet('colors.black_800')};
  font-size: 16px; // Body 1
  font-weight: 400; // Body 1
  letter-spacing: 0.5px; // Body 1
  line-height: 24px; // Body 1
  margin: 16px 0 0 0;
`

export const TitleRibbonRow = styled(Flex)`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  align-items: center;
`
export const ShowAllRibbonRow = styled(Flex)`
  letter-spacing: 1.5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #455368;
  cursor: pointer;
  align-items: center;
  margin-right: 16px;
  :hover {
    text-decoration: underline;
  }
`

export const ButtonWithBadgeContainer = styled.div`
  position: relative;
  display: inline-block;
`

export const Badge = styled.span`
  position: absolute;
  top: -10px;
  right: 0;
  background-color: ${themeGet('colors.primary')};
  color: white;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const HeaderModal = styled(Flex).attrs(() => ({
  ml: 4,
}))``

export const StringContainer = styled(Flex).attrs(() => ({
  ml: 2,
}))`
  max-height: 100%;
`

export const ClearFilters = styled(Flex).attrs(() => ({
  fill: 'gray',
}))`
  position: absolute;
  top: 24px;
  right: 8px;
  width: 150px;
  height: 36px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.5px;
  cursor: pointer;
  &:hover:not(:focus-visible):not(:active) {
    text-decoration: underline;
  }
`

// SECTIONS
export const Section = styled(Flex).attrs(() => ({}))`
  flex-direction: column;
  flex: 1;
`

export const SectionTitle = styled(Flex).attrs(() => ({
  px: 5,
}))`
  flex: 1;
  min-height: 56px;
  justify-content: space-between;
  align-items: center;
  background: ${themeGet('colors.neutral_300')};
  font-family: ${walsheimAndFallbackFontFamily};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${props =>
    props.isTag ? themeGet('colors.black_600') : themeGet('colors.black_800')};

  letter-spacing: 0.5px;
`

export const Title = styled(Flex).attrs(() => ({}))`
  flex: 1;
`

export const SectionSubitle = styled(Flex).attrs(({ mt }) => ({
  px: 5,
  mt: mt || 3,
}))`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  color: ${themeGet('colors.black_600')};
  background: ${p => (p.secondary ? themeGet('colors.neutral_300') : '')};
`
export const SectionContent = styled(Flex).attrs(() => ({
  px: 4,
  pt: 3,
}))`
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
`

export const CheckboxContainerGrid = styled(Box)`
  column-count: 2;
  column-gap: 20px;
`

export const CheckboxLabel = styled(Flex).attrs(() => ({}))`
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  margin-left: 8px;
`
export const SelectAllContainer = styled(Flex).attrs(() => ({}))`
  cursor: pointer;
  font-weight: 400;
  min-width: 80px;
  font-size: 16px;
  color: ${themeGet('colors.black_600')};
  letter-spacing: 0.5px;
  &:hover:not(:focus-visible):not(:active) {
    text-decoration: underline;
  }
`
export const CheckboxContainer = styled(Flex).attrs(() => ({ px: 3 }))`
  height: 50px;
  break-inside: avoid;
  &:hover:not(:focus-visible):not(:active) {
    background-color: ${themeGet('colors.primary_60_50')};
    border-radius: 50px;
    width: fit-content;
  }
  cursor: pointer;
  align-items: center;
`

// ACCORDION

export const AccordionItemButton = styled(ReactAccordionItemButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${themeGet('colors.neutral_300')};
  padding-left: 25px;
  border-bottom: 1px solid ${themeGet('colors.black_300')};
  cursor: pointer;

  ::before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
    transition: transform 0.15s ease-in-out;
    color: ${props =>
      props.isTag
        ? themeGet('colors.black_600')
        : themeGet('colors.black_800')};
  }

  &[aria-expanded='true']::before {
    transform: rotate(-135deg);
  }
`

export const AccordionItem = styled(ReactAccordionItem)`
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const Footer = styled(Flex)`
  justify-content: center;
  background-color: white;
  padding-bottom: 20px;
  padding-top: 20px;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: ${shadows.skim};
`
