import { getViewer } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { I18n, getTranslation } from '@lyfta/components-i18n'
import { ButtonNew, Flex, USER_TYPES } from '@lyfta/components-ui'
import { StudentApp } from '@lyfta/components-ui/src/Assets/Sidebar'
import { isEmpty, map } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { loadLessons } from '../../Store/Actions/lessons'
import { HomeLessonsScreenComponent } from './HomeLessonsScreenComponent'
import LessonRow from './Row'
import {
  Description,
  DescriptionText,
  ExploreButton,
  ExploreIconContainer,
  Header,
  InputContainer,
  InputText,
  LessonExploreText,
  LessonInfo,
  LessonPassphrase,
  LessonsBackground,
  LessonsContainer,
  LessonsScreen,
  MainTitle,
  SearchIcon,
} from './styles'

const LessonSelection = ({
  resetCurrentLesson,
  redirectHome,
  onLoadLesson,
  onLoadLessons,
  isFocused,
  lessonLoaded,
  lessons,
  resourceId,
  viewerType,
  onResumeWebGL,
  onSelectLesson,
  onSetStudentLessonId,
  onSetIsTeachingInFrontOfClass,
}) => {
  const dispatch = useDispatch()

  const handleLessonButton = lessId => () => {
    if (
      window.parent &&
      window.parent !== window.self &&
      window.parent.postMessage
    ) {
      window.parent.postMessage(
        {
          request: 'lesson-selection',
          lessId,
        },
        '*',
      )
      return
    }

    resetCurrentLesson()
    if (lessId > 0) {
      onLoadLesson(lessId)
      onSetStudentLessonId(lessId)
      onSetIsTeachingInFrontOfClass(true)
    } else {
      onSetStudentLessonId(null)
      onSetIsTeachingInFrontOfClass(false)
    }
    onResumeWebGL()
    onSelectLesson(lessId)
  }
  const [searchText, setSearchText] = useState('')

  const viewer = useSelector(getViewer)

  useEffect(() => {
    if (
      viewerType &&
      (viewerType === USER_TYPES.teachers ||
        viewerType === USER_TYPES.homeStudents) &&
      isFocused
    ) {
      onLoadLessons(
        viewerType,
        viewer?.permissions?.organizationAdmin ? viewer.id : null,
      )
    }
  }, [viewer.id, viewerType, isFocused])

  useEffect(() => {
    if (lessonLoaded && !lessons.length) {
      setTimeout(() => {
        onSelectLesson(0)
      }, 200)
    }
  }, [lessons])

  useEffect(() => {
    if (
      viewerType &&
      !(
        viewerType === USER_TYPES.teachers ||
        viewerType === USER_TYPES.homeStudents
      )
    ) {
      redirectHome()
    }
    if (resourceId) redirectHome()
  }, [])

  if (!viewerType) return null

  const handleChange = ({ target: { value } }) => {
    setSearchText(value)
  }

  const onClickLabel = () => {
    dispatch(
      loadLessons(null, null, !isEmpty(searchText) ? { name: searchText } : {}),
    )
  }
  const renderLessons = () => (
    <LessonsContainer>
      <Header>
        <I18n tag={MainTitle} text="teachers.chooseLesson" />
        <Description>
          {viewerType !== USER_TYPES.homeStudents && (
            <I18n
              tag={DescriptionText}
              text="teachers.chooseLessonDescription"
            />
          )}
        </Description>
      </Header>
      <InputContainer>
        <SearchIcon />
        <Flex
          flexDirection="row"
          width="95%"
          justifyContent="space-around"
          ml={4}
        >
          <InputText
            id="search-lessons"
            placeholder={getTranslation('core.search.placeholderName')}
            // value={search.string}
            onChange={handleChange}
          />
          <ButtonNew
            default={{
              // padding: '10px 30px',
              height: '30px',
              fontSize: '18px',
            }}
            onClick={onClickLabel}
          >
            <I18n text="actions.search" />
          </ButtonNew>
        </Flex>
      </InputContainer>

      <ExploreButton onClick={handleLessonButton(0)}>
        <ExploreIconContainer>
          <StudentApp color="white" fill="white" height={38} width={38} />
        </ExploreIconContainer>
        <LessonInfo>
          <I18n tag={LessonExploreText} text="teachers.exploreContent" />
        </LessonInfo>

        <LessonPassphrase background="transparent" />
      </ExploreButton>

      {map(lessons, (lessonItem, i) => {
        return (
          <LessonRow
            handleLessonButton={handleLessonButton}
            key={i}
            lesson={lessonItem}
            viewerType={viewerType}
          />
        )
      })}
    </LessonsContainer>
  )

  if (viewerType === USER_TYPES.homeStudents) {
    return (
      <HomeLessonsScreenComponent
        handleLessonButton={handleLessonButton}
        lessons={lessons}
        viewer={viewer}
        viewerType={viewerType}
      />
    )
  }

  // TODO: Need to have a native compatible logo here before deployment
  return (
    <LessonsScreen>
      <LessonsBackground>
        {lessons.length ? renderLessons() : null}
      </LessonsBackground>
    </LessonsScreen>
  )
}

LessonSelection.defaultProps = {
  isFocused: false,
  lessons: [],
  lessonLoaded: false,
  resourceId: null,
  viewerType: null,
}
LessonSelection.propTypes = {
  isFocused: PropTypes.bool,
  lessonLoaded: PropTypes.bool,
  lessons: PropTypes.array,
  redirectHome: PropTypes.func.isRequired,
  resetCurrentLesson: PropTypes.func.isRequired,
  resourceId: PropTypes.string,
  viewerType: PropTypes.string,
  onLoadLesson: PropTypes.func.isRequired,
  onLoadLessons: PropTypes.func.isRequired,
  onResumeWebGL: PropTypes.func.isRequired,
  onSelectLesson: PropTypes.func.isRequired,
  onSetIsTeachingInFrontOfClass: PropTypes.func.isRequired,
  onSetStudentLessonId: PropTypes.func.isRequired,
}

export default LessonSelection
