import { loadSubjects } from '@lyfta/components-data/src/Store/Actions/subjects'
import { getSubjects } from '@lyfta/components-data/src/Store/Selectors/subjects'
import { I18n, getTranslation } from '@lyfta/components-i18n'
import { themeGet } from '@lyfta/components-theme'
import shadows from '@lyfta/components-theme/src/themes/Main/shadows'
import {
  ButtonNew,
  Filter,
  Flex,
  Icon,
  Modal,
  ReactSelectDropdown,
  TopBar,
} from '@lyfta/components-ui'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { components } from 'react-select'

import { loadTagGroups } from '../../Store/Actions/tagGroups'
import { loadTags } from '../../Store/Actions/tags'
import {
  getFiltersInfo,
  getFiltersInfoEditor,
} from '../../Store/Selectors/search'
import { getTagGroups } from '../../Store/Selectors/tagGroups'
/* eslint-disable */
import SearchBlock from '../Search'
import modalFilterForm from './modalFilterForm'
import {
  Badge,
  ButtonWithBadgeContainer,
  StringContainer,
  TopBarLeft,
} from './styles'

const Search = props => {
  const dispatch = useDispatch()

  const filterString = get(props, 'filterString')
  const pager = get(props, 'pager')
  const setFilter = get(props, 'setFilter')
  const setSortBy = get(props, 'setSortBy')
  const optionChoosen = get(props, 'optionChoosen')
  const lyftaContent = get(props, 'lyftaContent')
  const isCollectionEditor = get(props, 'isCollectionEditor')
  const isTrialing = get(props, 'isTrialing')

  const [showFilterdModal, setShowFilterdModal] = useState(false)

  const tagGroups = useSelector(getTagGroups)
  const subjects = useSelector(getSubjects)

  useEffect(() => {
    if (tagGroups.length === 0) {
      dispatch(loadTagGroups())
      dispatch(loadSubjects())
      dispatch(
        loadTags({
          page: { size: 250 },
        }),
      )
    }
  }, [tagGroups, subjects, dispatch])

  const { totalTrueItems, itemsString } =
    isCollectionEditor || lyftaContent
      ? useSelector(getFiltersInfoEditor)
      : useSelector(getFiltersInfo)

  const handleFilterModalSubmit = (items, resetNumPage = false) => {
    if (resetNumPage) pager.choosePage(1)

    setFilter(items)
    setShowFilterdModal(!showFilterdModal)
  }
  const handleSortBy = item => {
    setSortBy(item)
  }

  const handleOpenFilterModal = () => {
    setShowFilterdModal(!showFilterdModal)
  }

  const options = [
    {
      value: 'name',
      label: <I18n i18nKey="filters.sort.name" />,
    },
    {
      value: '-createdAt',
      label: <I18n i18nKey="filters.sort.newest" />,
    },
    {
      value: 'relevant',
      label: <I18n i18nKey="filters.sort.relevant" />,
    },
    {
      value: '-viewedCount',
      label: <I18n i18nKey="filters.sort.viewed_count" />,
    },
  ]

  if (isTrialing) {
    options.push({
      value: 'unlocked_content',
      label: <I18n i18nKey="filters.sort.unlocked_content" />,
    })
  }

  const renderRightContainer = () => {
    // Custom SingleValue component
    const CustomSingleValue = ({ children, ...props }) => {
      return (
        <components.SingleValue {...props}>
          {getTranslation('filters.sort.label')} <strong>{children}</strong>
        </components.SingleValue>
      )
    }

    return (
      <Flex>
        {filterString && (
          <ReactSelectDropdown
            defaultValue={
              options.find(option => option.value === optionChoosen) ||
              options[0]
            }
            menuPlacement="bottom"
            showRadioIcon
            extendStyles={{
              container: defaultStyles => ({
                ...defaultStyles,
                marginRight: '20px',
                paddingLeft: '20px',
                paddingRight: '10px',
                minWidth: '240px',
                border: `1px solid #455368`,
                borderRadius: '5px',
                height: '40px',
                backgroundColor: '#fff',
                zIndex: '100',
              }),
              control: defaultStyles => ({
                ...defaultStyles,
                minHeight: '40px',
              }),
              menu: defaultStyles => ({
                ...defaultStyles,
                left: 'initial',
                right: '0',
                width: '240px',
              }),
            }}
            id="statusFilter"
            options={options}
            onChange={event => handleSortBy(event.value)}
            extendComponents={{ SingleValue: CustomSingleValue }}
          />
        )}

        <ButtonWithBadgeContainer id="btn-filter-content-badge">
          <ButtonNew
            data-intercom-target="filter-btn"
            id="btn-filter-content"
            key="filter-button"
            leadingIcon={
              <Icon
                glyph={Filter}
                mr={2}
                fill={themeGet('colors.secondary_100')}
                height={18}
                width={18}
              />
            }
            borderColor="hsla(238, 63%, 49%, 1)"
            color="hsla(238, 63%, 49%, 1)"
            backgroundColor="white"
            fill="hsla(238, 63%, 49%, 1)"
            fontWeight="bold"
            hover={{
              backgroundColor: 'hsla(238, 63%, 49%, 1)',
              color: 'white',
              fill: 'white',
            }}
            title={getTranslation('actions.filterResults')}
            type="cta"
            secondary
            onClick={handleOpenFilterModal}
            googleEvent={{ action: 'clicked_search_filter_results' }}
          >
            {getTranslation('actions.filterResults')}
          </ButtonNew>
          {totalTrueItems ? <Badge>{totalTrueItems}</Badge> : ''}
        </ButtonWithBadgeContainer>
      </Flex>
    )
  }

  const renderLeftContainer = () => {
    let finalString = ''

    if (filterString || itemsString) {
      if (pager) {
        finalString = getTranslation('search.resultMatching', {
          total: pager.total,
        })
      }

      if (filterString) {
        // eslint-disable-next-line
        finalString += ' ' + filterString
      } else if (itemsString) {
        // eslint-disable-next-line
        finalString += ' ' + itemsString
      }

      return (
        <StringContainer>
          <I18n id="items-info" i18nKey={finalString.trim()} tag={TopBarLeft} />
        </StringContainer>
      )
    }

    return (
      <StringContainer>
        {isCollectionEditor ? (
          <I18n
            id="items-info"
            i18nKey="search.topBarLeftCollectionEditor"
            tag={TopBarLeft}
          />
        ) : (
          <I18n id="items-info" i18nKey="search.topBarLeft" tag={TopBarLeft} />
        )}
      </StringContainer>
    )
  }

  const fieldsProperties = {
    okText: getTranslation('actions.showResults', { number: pager.total }),
    handleOpenFilterModal,
    tagGroups,
    subjects,
    lyftaContent,
    isCollectionEditor,
  }
  return (
    <>
      <SearchBlock
        googleEvent={{ action: 'clicked_search' }}
        mx={5}
        {...props}
      />
      <TopBar
        mx={5}
        mt={3}
        height="60px"
        leftContainer={renderLeftContainer()}
        rightContainer={renderRightContainer()}
      />
      <Modal
        showFooter={false}
        hideClose
        maxWidth="700px"
        form={{
          fields: modalFilterForm(
            items => handleFilterModalSubmit(items, true),
            fieldsProperties,
          ),

          validate: () => {},
          showFormButtons: false,
        }}
        isOpen={showFilterdModal}
        modalStyle={{
          removePaddingX: true,
          headerContainer: {
            shadow: shadows.skim,
            padding: '24px 0px',
          },
          titleContainer: {
            marginLeft: '32px',
            mb: '0px',
          },
          subtitleContainer: {
            marginLeft: '32px',

            marginBottom: '20px',
          },
          contentContainer: {
            specialOverflowSettings: true,
          },
          footerContainer: {
            paddingTop: '0px',
          },
          wrapperContainer: {
            padding: '0px',
            margin: '0px 0px ',
          },
        }}
        onDismissModal={handleOpenFilterModal}
        onCallback={handleFilterModalSubmit}
        content={{
          title: 'search.filterResultsTitle',
          subtitle: filterString ? (
            <I18n
              i18nKey="search.filterResultsSubtitleWithQuery"
              options={{ query: filterString }}
            />
          ) : (
            'search.filterResultsSubtitle'
          ),
        }}
      />
    </>
  )
}

Search.defaultProps = {}

Search.propTypes = {}

export default Search
